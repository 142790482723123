'use client';

import React from 'react';

type PrivacySettingsConfig = { [key in PrivacySettings]: boolean };

type CookieConsentContextProps = [
  { isConsentValueExist: boolean; privacySettings: PrivacySettingsConfig },
  {
    updateSettings: (cfg: PrivacySettingsConfig) => void;
  },
];

enum PrivacySettings {
  Analytics = 'analytics_cookies',
  Other = 'other_cookies',
}

const CookieConsentContext = React.createContext<CookieConsentContextProps>([
  {
    isConsentValueExist: false,
    privacySettings: { [PrivacySettings.Analytics]: false, [PrivacySettings.Other]: false },
  },
  { updateSettings: () => {} },
]);

function CookieConsentProvider<T extends { children: React.ReactNode; initValue?: string }>({
  children,
  initValue,
}: T) {
  const [isConsentValueExist, setIsConsentValueExist] = React.useState(!!initValue);
  const [privacySettings, setPrivacySettings] = React.useState<PrivacySettingsConfig>({
    [PrivacySettings.Analytics]: initValue
      ? !!Number(new URLSearchParams(initValue).get(PrivacySettings.Analytics))
      : false,
    [PrivacySettings.Other]: initValue ? !!Number(new URLSearchParams(initValue).get(PrivacySettings.Other)) : false,
  });

  console.log('privacySettings', privacySettings);

  const updateSettings = React.useCallback((conf: PrivacySettingsConfig) => {
    setPrivacySettings(conf);
    setIsConsentValueExist(true);
  }, []);

  const value = React.useMemo(
    () => [{ isConsentValueExist, privacySettings }, { updateSettings }] as CookieConsentContextProps,
    [isConsentValueExist, privacySettings, updateSettings],
  );

  return <CookieConsentContext.Provider value={value}>{children}</CookieConsentContext.Provider>;
}

function useCookieConsent() {
  return React.useContext(CookieConsentContext);
}

export default CookieConsentProvider;
export { useCookieConsent };
